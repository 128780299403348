
import { Options, Vue } from "vue-class-component";
import { INote } from "@/types/Note";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import DropdownComponent from "@/components/DropdownComponent.vue";
import { notify } from "@/services/helpers";

@Options({
  name: "NewNote",
  components: {
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
    Dropdown: DropdownComponent,
  },
  data: function () {
    return {
      schema: yup.object().shape({
        name: yup
          .string()
          .required("Please enter a subject.")
          .max(50, "Must not exceed 20 characters in length."),
        detail: yup
          .string()
          .required("Please provide a detailed description.")
          .max(200, "Must not exceed 200 characters in length."),
        status: yup.string().required("Please select a status."),
      }),
    };
  },
  props: {},
})
export default class NewNote extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  newNote: INote = {
    id: 0,
    name: "",
    detail: "",
    status: "",
    employeeId: 0,
    createdOn: "",
  };
  crumbs: any = ["Dashboard", "Employees", "Notes", "New Note"];
  loading = false;
  dropdownData: any = [
    { value: "Employee", Type: "Employee" },
    { value: "Private", Type: "Private" },
    { value: "Incident", Type: "Incident" },
  ];

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  async save() {
    if (this.newNote.status == "Public") {
      const modal = await (this.$refs["confirmDialogue"] as any).show({
        title: "Attention!",
        message:
          "Are you sure you want to create a note with Employee visibility?",
        okButton: "Ok, Proceed",
        cancelButton: "Cancel",
        theme: "primary",
        type: "confirmation",
      });
      if (modal) {
        await this.saveNote();
      } else {
        return false;
      }
    } else {
      await this.saveNote();
    }
  }

  message = "";
  async saveNote() {
    this.loading = true;
    this.message = "";
    await this.workForceService
      .postOrPut<void>("/notes", this.newNote as INote, undefined)
      .then((response: any) => {
        if (!response.isError) {
          notify("Note added successfully.", "Success", "success");
          this.goToRoute(`/admin/notes/${this.$route.params.id}`);
        } else {
          notify(response.errors, "Error", "danger");
        }
      });
    this.loading = false;
  }

  async created() {
    this.newNote.employeeId = +this.$route.params.id;
  }
}
